
























































import { getCounter, bindUpi, quickpayBindBank } from '@/api'
import router from '@/router'
import { AppModule } from '@/store/modules/app'
import { Component, Vue } from 'vue-property-decorator'
import Cookies from 'js-cookie'

@Component({
  name: 'selBank'
})
export default class extends Vue {
  public orderInfo: any = {
    amount: '',
    bank: [],
    is_expired: false,
    ordernumber: '',
    status: 0
  }
  public paybank: string = ''
  public form: any = {
    upi: ''
  }

  public isShowSuffix: boolean = false

  public blur() {
    setTimeout(() => {
      this.isShowSuffix = false
    }, 100)
  }

  public setInput(mail) {
    this.form.upi = mail
  }

  public get upiArr() {
    this.isShowSuffix = true
    let upiArr = [
      'apl',
      'abfspay',
      'axisb',
      'axl',
      'barodampay',
      'centralbank',
      'cbin',
      'cboi',
      'cnrb',
      'ezeepay',
      'eazypay',
      'fbl',
      'hdfcbank',
      'hsbc',
      'hdfcbankjd',
      'hsbc',
      'Idbi',
      'imobile',
      'icici',
      'idbibank',
      'ikwik',
      'ibl',
      'jupiteraxis',
      'kotak',
      'kaypay',
      'kmb',
      'kmbl',
      'mahb',
      'myicici',
      'okicici',
      'okhdfcbank',
      'okaxis',
      'oksbi',
      'paytm',
      'pockets',
      'payzapp',
      'pnb',
      'pingpay',
      'rajgovhdfcbank',
      'Sbi',
      'timecosmos',
      'Ubi',
      'united',
      'utbi',
      'waaxis',
      'wahdfcbank',
      'wasbi',
      'Yesbank',
      'yesbankltd',
      'ybl',
      'yesbank'
    ]
    if (this.form.upi.indexOf('@') > -1) {
      if (this.form.upi.length > this.form.upi.indexOf('@')) {
        let str = this.form.upi.slice(
          this.form.upi.indexOf('@') + 1,
          this.form.upi.length + 1
        )
        return upiArr.map(item => {
          if (
            item.toLowerCase().indexOf(str.toLowerCase()) === 0 ||
            item.toUpperCase().indexOf(str.toUpperCase()) === 0
          ) {
            let option =
              this.form.upi.slice(0, this.form.upi.indexOf('@') + 1) + item
            return option
          }
        })
      }
    }
    if (!this.form.upi) {
      return []
    }
  }

  public poll: any = null
  public timer: any = null
  public time: number = 30
  public loading: boolean = false
  public outt: any = null
  public async getCounter() {
    try {
      await getCounter().then((res: any) => {
        let language =
          res.currency === 'THB' ? 'th' : res.currency === 'VND' ? 'vi' : 'en'
        this.$i18n.locale = language
        AppModule.SetLanguage(language)
        if (res.status === 0 && res.is_expired) {
          // 订单未支付 且订单过期
          router.replace({ name: 'error' })
        } else if (res.status === 1) {
          // 订单已支付
          router.replace({ name: 'success' })
        } else if (res.extra_param?.paybank) {
          this.$router.replace({
            name: 'submit',
            params: { orderInfo: this.orderInfo }
          })
        } else {
          // 订单未支付 且订单未过期
          if (!this.poll) {
            this.form.upi = res.extra_param.upi ? res.extra_param.upi : ''
            // 轮询未开始，则开始轮询
            this.poll = window.setInterval(() => {
              this.getCounter()
            }, 5000)
          }
          // if (res.extra_param.bind_upi) {
          if (Cookies.get('upi')) {
            if (
              router.currentRoute.path === '/new_indiah5/selBank' &&
              !this.timer
            ) {
              if (res.extra_param.go_utr) {
                router.push({ name: 'submit' })
              } else if (!this.outt) {
                this.outt = setTimeout(() => {
                  this.loading = true
                  this.timer = window.setInterval(() => {
                    if (--this.time <= 0) {
                      window.clearInterval(this.timer)
                      this.loading = false
                      this.time = 30
                      this.timer = null
                      this.setGoStatus()
                      if (this.orderInfo.status === 0) {
                        router.push({ name: 'submit' })
                      } else {
                        router.replace({ name: 'success' })
                      }
                    }
                  }, 1000)
                }, 5000)
              }
            } else if (router.currentRoute.path !== '/new_indiah5/selBank') {
              this.loading = false
            }
          } else {
            this.loading = false
          }
          this.orderInfo = res
          if (!this.paybank) {
            this.paybank = res.bank[0].redirect_url
          }
        }
        if (res.is_expired && this.poll) {
          window.clearInterval(this.poll)
          this.poll = null
        }
      })
    } catch (e) {}
  }

  public payment() {
    if (!this.paybank) {
      this.$msgbox.alert(`Please select bank`, {
        showClose: false,
        confirmButtonText: 'OK',
        type: 'warning',
        callback: () => {
          console.log(`Please select bank and enter UPI`)
        }
      })
    } else {
      this.quickpayBindBank()
      // setTimeout(() => {
      //   window.location.href = this.paybank
      // }, 500)
    }
  }

  public async setGoStatus() {
    await quickpayBindBank({ go_utr: '1' })
  }

  public async quickpayBindBank() {
    if (this.loading) return
    try {
      sessionStorage.setItem('upi', 'upi')
      Cookies.set('upi', 'upi')
      let url = this.paybank
      setTimeout(() => {
        if (top) {
          top.window.location.href = url
        } else {
          window.location.href = url
        }
      }, 500)
      // await bindUpi(this.form).then(() => {
      //   setTimeout(() => {
      //     window.location.href = this.paybank
      //   }, 500)
      // })
    } catch (e) {
      this.loading = false
    }
  }

  public getTime() {
    let res = '00:'
    if (this.time < 10) {
      res += `0${this.time}`
    } else {
      res += `${this.time}`
    }
    return res
  }
  created() {
    AppModule.SetNavbar(false)
  }
  mounted() {
    if (window.screen.availWidth <= 475 && window.screen.availWidth >= 310) {
      this.getCounter()
    } else {
      this.$confirm(`Please open this link in your mobile phone`, 'Tips')
        .then(() => {})
        .catch(() => {
          router.replace({ name: 'error' })
        })
    }
  }
}
